import { ChevronUpIcon } from "@heroicons/react/24/outline";
import {
  Accordion as AccordionWrapper,
  AccordionBody,
  AccordionHeader,
} from "@material-tailwind/react";
import React from "react";

interface Props {
  openArray: Array<boolean>;
  handleClick: (idx: number) => void;
  items: Array<{
    header: string | JSX.Element;
    body: JSX.Element[] | JSX.Element;
  }>;
}

/**
 * An accordion component.
 */
export default function Accordion({ openArray, handleClick, items }: Props) {
  return (
    <div>
      {items.map((item, idx) => {
        const open = idx >= openArray.length ? false : openArray[idx];
        return (
          <AccordionWrapper
            open={open}
            key={idx}
            icon={
              <ChevronUpIcon
                className={`${
                  open ? "rotate-180" : ""
                } h-5 w-5 transition-transform`}
              />
            }
            placeholder={""}
          >
            <AccordionHeader
              onClick={() => handleClick(idx)}
              className="text-lg font-semibold text-gray-800"
              placeholder={""}
            >
              {item.header}
            </AccordionHeader>
            <AccordionBody>{item.body}</AccordionBody>
          </AccordionWrapper>
        );
      })}
    </div>
  );
}
