import Modal from "components/common/modal";
import { Spinner, TextInput } from "flowbite-react";
import { displayErrors } from "helpers/errors";
import React, { useState } from "react";
import { submitFeedItemReport } from "redux/feed/feedItemReportsSlice";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { isDispatchResponseError } from "redux/utils";
import { FeedItem } from "types/feed";

interface Props {
  show: boolean;
  setShow: (val: boolean) => void;
  feedItem: FeedItem;
}

const DEFAULT_DESCRIPTIONS = [
  "It's spam",
  "Nudity or sexual activity",
  "Hate speech or symbols",
  "Violence or dangerous organizations",
  "False information",
  "Scam or fraud",
  "Suicide or self-injury",
  "Unlawful/illegal content",
];

/**
 * Modal used for reporting feed items.
 */
export function ReportFeedItemModal({ show, setShow, feedItem }: Props) {
  // Stage 0: select report option
  // Stage 1: display an input field if user clicked "Something else"
  // stage 2: confirmation
  const [stage, setStage] = useState(0);
  const [descriptionInput, setDescriptionInput] = useState("");
  const dispatch = useAppDispatch();
  const errorsSubmitFeedItemReport = useAppSelector(
    (state) => state.feedItemReports.errorsSubmitFeedItemReport,
  );
  const pendingSubmitFeedItemReport = useAppSelector(
    (state) => state.feedItemReports.pendingSubmitFeedItemReport,
  );

  /**
   * Reset modal.
   */
  function onClose() {
    setStage(0);
    setDescriptionInput("");
    setShow(false);
  }

  /**
   * Handle submitting the form.
   */
  async function handleSubmit(description: string) {
    const response = await dispatch(
      submitFeedItemReport({ description, feed_item: feedItem.id }),
    );

    if (!isDispatchResponseError(response)) {
      setStage(2);
    }
  }

  /**
   * Automatically submit the form if user clicked on one of the default options.
   */
  function onClickDescription(description: string) {
    handleSubmit(description);
  }

  /**
   * Go to stage 1.
   */
  function onClickSomethingElse() {
    setStage(1);
  }

  return (
    <Modal
      show={show}
      onClose={onClose}
      hideFooter={stage !== 1}
      onSubmit={() => handleSubmit(descriptionInput)}
      headerText="Report"
      body={
        <div className="space-y-4 flex flex-col ">
          {pendingSubmitFeedItemReport ? (
            <div className="flex justify-center items-center">
              <Spinner size="xl" aria-label="Loading" />
            </div>
          ) : (
            <>
              {stage === 0 && (
                <>
                  <div>Why are you reporting this item?</div>
                  <div className="space-y-2 font-semibold">
                    {DEFAULT_DESCRIPTIONS.map((description, idx) => (
                      <button
                        key={idx}
                        className="py-2 w-full text-left"
                        onClick={() => onClickDescription(description)}
                        aria-label={`Report for ${description}`}
                      >
                        {description}
                      </button>
                    ))}
                    <button
                      className="py-2 w-full text-left"
                      onClick={onClickSomethingElse}
                      aria-label="Report for something else"
                    >
                      Something else
                    </button>
                  </div>
                </>
              )}

              {stage === 1 && (
                <>
                  <div>Why are you reporting this item?</div>
                  <TextInput
                    type="text"
                    placeholder="Please provide a reason"
                    value={descriptionInput}
                    onChange={(e) => setDescriptionInput(e.target.value)}
                    aria-label="Report reason input"
                  />
                </>
              )}

              {stage === 2 && (
                <>
                  <div>
                    Thank you for letting us know. <br />
                    Our team will soon review your report and take appropriate
                    action.
                  </div>
                </>
              )}

              {Object.keys(errorsSubmitFeedItemReport).map((key) => (
                <div key={key}>
                  {displayErrors(errorsSubmitFeedItemReport[key])}
                </div>
              ))}
            </>
          )}
        </div>
      }
    />
  );
}
