import React, { useState } from "react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
} from "@material-tailwind/react";
import Video from "../common/video";
import { ChevronUpIcon } from "@heroicons/react/24/solid";
import { Playlist } from "types/playlist";
import Track from "../common/track";
import News from "../common/news";
import { FEED_ITEM_TYPES } from "types/feed";

interface Props {
  playlist: Playlist;
}

/**
 * A playlist that can be expanded to show its items.
 */
export default function ExpandablePlaylist({ playlist }: Props) {
  const [open, setOpen] = useState(false);

  /**
   * Toggle the open state of the accordion.
   */
  const handleOpen = () => {
    setOpen(!open);
  };

  /**
   * Render items in the playlist.
   */
  const displayVideos = () => {
    const items = playlist.items;

    if (items.length > 0) {
      return items.map((item, idx) => {
        let innerItem = null;
        if (item.feed_item?.type === FEED_ITEM_TYPES.VIDEO) {
          innerItem = <Video video={item.feed_item} />;
        }
        if (item.feed_item?.type === FEED_ITEM_TYPES.TRACK) {
          innerItem = <Track track={item.feed_item} />;
        }
        if (item.feed_item?.type === FEED_ITEM_TYPES.NEWS) {
          innerItem = <News news={item.feed_item} />;
        }
        return (
          <li key={idx} className="p-4">
            {innerItem}
          </li>
        );
      });
    } else {
      return (
        <p className="p-4 text-gray-500 text-sm font-semibold">
          This playlist has no items!
        </p>
      );
    }
  };

  /**
   * Get the length of the playlist.
   */
  function getPlaylistLength() {
    const length = playlist.items.length;
    return length;
  }

  return (
    <Accordion
      open={open}
      icon={
        <ChevronUpIcon
          className={`${open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
          aria-hidden="true"
        />
      }
      placeholder={""}
    >
      <AccordionHeader
        onClick={() => handleOpen()}
        className="flex py-3 px-4 bg-gray-100"
        placeholder={""}
        aria-label={`Toggle ${playlist.name} playlist`}
      >
        <div className="flex flex-col flex-1 items-start">
          <h4 className="text-base font-semibold text-gray-700 truncate">
            {playlist.name}
          </h4>
          <span className="text-sm text-gray-500 truncate">
            {getPlaylistLength()} items
          </span>
        </div>
      </AccordionHeader>
      <AccordionBody className="p-0">
        <ul className="divide-y divide-gray-200">{displayVideos()}</ul>
      </AccordionBody>
    </Accordion>
  );
}
