import React, { useEffect, useState } from "react";
import { sequence } from "0xsequence";
import SequenceIcon from "components/icons/SequenceIcon";
import { Button } from "flowbite-react";

const BUTTON_CLASSNAME =
  "flex flex-row justify-center items-center w-full bg-slate-800 hover:bg-slate-900";

/**
 * Component for displaying, connecting & disconnecting a Sequence wallet.
 */
export default function SequenceWallet() {
  const [isWalletConnected, setIsWalletConnected] = useState(false);

  async function initWallet() {
    return sequence.initWallet("mainnet");
  }

  async function connectWallet() {
    const wallet = await initWallet();
    const connectDetails = await wallet.connect({
      app: "Brain Cargo",
      authorize: true,
    });
    await checkWalletConnectionStatus();
  }

  async function checkWalletConnectionStatus() {
    const wallet = await initWallet();
    setIsWalletConnected(wallet.isConnected());
  }

  async function viewWallet() {
    const wallet = await initWallet();
    wallet.openWallet();
  }

  async function disconnectWallet() {
    const wallet = await initWallet();
    await wallet.disconnect();
    await checkWalletConnectionStatus();
  }

  useEffect(() => {
    checkWalletConnectionStatus();
  }, []);

  return (
    <div className="flex flex-col justify-center items-center bg-slate-900 rounded-lg text-white gap-4 p-4">
      <div
        className="flex flex-row justify-center items-center p-2 gap-2 text-2xl select-none"
        onClick={viewWallet}
        role="button"
        tabIndex={0}
        aria-label="View wallet"
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            viewWallet();
          }
        }}
      >
        <SequenceIcon className="h-10" />
        Sequence.xyz
      </div>
      <div className="flex flex-col w-1/2 gap-2">
        {isWalletConnected ? (
          <>
            <Button
              className={BUTTON_CLASSNAME}
              onClick={viewWallet}
              aria-label="View wallet"
            >
              <span className="text-slate-200 font-bold text-xl">
                View wallet
              </span>
            </Button>
            <Button
              className={`${BUTTON_CLASSNAME}`}
              onClick={disconnectWallet}
              aria-label="Disconnect wallet"
            >
              <span className="text-red-600 font-bold text-xl">
                Disconnect wallet
              </span>
            </Button>
          </>
        ) : (
          <Button
            className={BUTTON_CLASSNAME}
            onClick={connectWallet}
            aria-label="Connect wallet"
          >
            <span className="text-slate-200 font-bold text-xl">
              Connect wallet
            </span>
          </Button>
        )}
      </div>
    </div>
  );
}
